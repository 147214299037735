import React from "react";

import masterclasspic from "../../assets/images/learn/mastering-being-masterclass.gif";

import { Link } from "gatsby";

export default function Masterclass() {
  return (
    <section className="master-class-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={masterclasspic} alt="youtube" />
          </div>
          <div className="col-md-6 mt-5">
            <h1 className="masterclass-theme text-center">
              Mastering 
            </h1>
            <h1 className="masterclass-theme-sub master-being  text-center">Being</h1>
            <div className="custom-width-master">
            
             
                <p className="theme-color being-ptag">  Begin anytime with Heartfulness. </p>
                <p className="theme-p-tag">
                 
              
                <br /> Learn to manage your life and your emotions <br />
                the Heartfulness way. <br />
                An online series of 3 free meditation masterclasses with Daaji.
              </p>

             
              <div className="col-md-12 col-sm-12 col-xs-12 text-center mt-5">
                <Link to="#daybeginbeing" className="btn btn-blue rdmore-pad">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </section>
    
 

 );
}
