import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// import Aboutbreadcreumb from "../components/about/about-breadcumbs";

import Masterbreadcreumb from "../components/learn/master-breadcumbs";

import Masterclass from "../components/learn/master-class";

import Daybegin from "../components/learn/day-begin";

import Schedulesec from "../components/learn/schedule-sec";

import Guidebooks from "../components/learn/guide-book";

// import Registermasterclass from "../components/learn/register-masterclass";

import Meetdaaji from "../components/learn/meet-daaji";

// import Questionform from "../components/learn/question-form";

import Contactinfo from "../components/learn/contact-info";

// markup
const MasterPage = () => {
  return (
    <div>
      <Seo title="Learn" />
      <Layout isSticky>
        <section>
          <Masterbreadcreumb />
        </section>
        <section>
          <Masterclass />
        </section>
        <section>
          <Daybegin />
        </section>
        <section>
          <Schedulesec />
        </section>
        {/* <section>
          <Registermasterclass />
        </section> */}
        <section>
          <Guidebooks />
        </section>
        <section>
          <Meetdaaji />
        </section>
        {/* <section>
          <Questionform />
        </section> */}
        <section>
          <Contactinfo />
        </section>
      </Layout>
    </div>
  );
};

export default MasterPage;
