import React from "react";

import daajiinfo from "../../assets/images/learn/daaji-info-img.png";

export default function Daybegin() {
  return (
    <section id="daybeginbeing" className="day-begin-wrapper pad60">
      
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <h1 class="section-block-title-daybegin text-center">
              Let your day begin
              <br />
              with Oneness
            </h1>
          </div>
          
        </div>

        <div className="row inner-block-width">
          <div className="col-md-6 text-center">
            <img className="bg-600" src={daajiinfo} alt="youtube" />
          </div>
          <div className="col-md-6">
            <p className="theme-p-tag py-5">
            In the masterclasses, Daaji shares his wisdom, knowledge, and experience as a lifelong student of meditation and spirituality. His approach is scientific and practical. There are 3 masterclasses, each one focusing on an essential element of Heartfulness practice. No prior meditation experience is needed to join in and feel the benefits for yourself.
            </p>
        

          
          </div>
        </div>
      
      </div>
      <hr />
    </section>
  );
}
