import React from "react";

import daajicircle from "../../assets/images/learn/daaji-img-circle.png";

import { Link } from "gatsby";

export default function Meetdaaji() {
  return (
    <section className="meetdaji-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 class="heading-wrapper mb-4"> Meet Daaji </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <img className="img-fluid" src={daajicircle} alt="youtube" />
          </div>
          <div class="col-md-10">
            <p class="text-justify theme-p-tag">
              The teachings of Kamlesh D. Patel, known as Daaji, arise from his
              personal experience on the path of Heartfulness, while reflecting
              his deep spirit of inquiry and respect for the world’s great
              spiritual traditions and scientific advancements. While deeply
              immersed in his own meditation practice for more than three
              decades, Daaji also worked as a successful pharmacist and
              entrepreneur in New York City. In 2015, he became the fourth guide
              in the century-old lineage of Heartfulness spiritual guides. He is
              the co-author, most recently, of{" "}
              <i>
                The Heartfulness Way: Heart-Based Meditations for Spiritual
                Transformation
              </i>
              . And here you can read his writings on the{" "}
              <Link
                to="http://www.heartfulnessmagazine.com/evolution-of-consciousness/"
                className="link-color pink-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                evolution of consciousness
              </Link>
              . To learn more about Daaji, go to{" "}
              <Link
                to="http://daaji.org/"
                className="link-color pink-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.daaji.org.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
