import React from "react";

import hfnwaybook from "../../assets/images/learn/hfnway-book.png";

// import orderamazon from "../../assets/images/learn/order-at-amazon-in.png";

// import orderflipkart from "../../assets/images/learn/order-at-flipkart-com.png";

// import ordershpt from "../../assets/images/learn/order-shpt.png";

import { Link } from "gatsby";

export default function Guidebooks() {
  return (
    <section className="guidebooks-wrapper pad60">
      <hr/>
      <div className="container">
        <div className="row">
          <div className="col-md-5 text-center">
            <img className="hfnway-book-img" src={hfnwaybook} alt="youtube" />
          </div>

          <div className="col-md-7 text-left pt-4">
            <h1 className="section-block-title mb-4">The guide book</h1>
            <p className="intro-line-event-new guide-font16">
              Discover a special collection of meditations and an exceedingly
              rare feature known as Transmission. Together, they awaken the
              depths of human potential and initiate a mystical inner journey.
              In this process, the heart becomes a perennial source of wisdom
              and guidance to help you navigate life’s twists and turns and lead
              a life more connected to yourself and others.
            </p>
            <p>
              The book is available all over. For info visit:
              <Link
                to="http://theheartfulnessway.com/"
                target="_blank"
                rel="noreferrer"
                type="button"
                className="pink-link"
              >
                http://theheartfulnessway.com/
              </Link>
            </p>
            {/* <a
              href="http://theheartfulnessway.com/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img className="wid190" src={orderamazon} alt="youtube" />{" "}
            </a>{" "}
            <a
              href="http://theheartfulnessway.com/"
              target="_blank"
              rel="noreferrer"
              type="button"
            >
              <img className="wid190" src={orderflipkart} alt="youtube" />
            </a>{" "}
            <a
              href="http://theheartfulnessway.com/"
              target="_blank"
              rel="noreferrer"
              type="button"
            >
              {" "}
              <img className="wid190" src={ordershpt} alt="Ordershift" />
            </a> */}
          </div>
        </div>
      </div>
      <hr/>
    </section>
  );
}
