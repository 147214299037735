import React from "react";
import { Link } from "gatsby";
export default function Contactinfo() {
  return (
    <section className="contact-info-wrapper pad60">
      <hr/>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper contact-font"> Contact Info </h3>
            <h4 className="theme-h4 text-center contact-p-tag" id="press-release-info">
              <span class="theme-black font-light">
                For more information,
                <br />
                please{" "}
                <Link
                  to="//cdn-prod.heartfulness.org/hfn/files/hfn-pressrelease-april-masterclasses-2017.pdf"
                  class="link-active pink-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  read
                </Link>{" "}
                the media release
              </span>
            </h4>

            <p class="theme-p-tag text-center theme-color">
              <a href="mailto:masterclass@heartfulness.org" className="">
                masterclass@heartfulness.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
