import React from "react";

import mcrelax from "../../assets/images/learn/mc-relax-icon.png";

import rejuvente from "../../assets/images/learn/mc-rejuvenate-icon.png";

import mcconnect from "../../assets/images/learn/mc-connect-icon.png";

// import Viewselectdrop from "../../components/learn/view-select";

export default function Schedulesec() {
  return (
    <section className="schedule-wrapper mc-event-info-content mt-4">
      <div className="container">
        {/* <div className="row">
          <div className="col-md-12">
            <h1 className="section-block-title text-center">Schedule</h1>
          </div>
        </div> */}

        <div id="select-your-language" className="time-section1">
          <div className=" row py-5">
            <div className="col-md-1">
              <h2 className="day-text text-center">Day</h2>
              <h2 className="month-text text-center">1</h2>
            </div>
            <div className="col-md-4">
              <div className="custom-left-border">
                <h4 className="time-title-intro">
                  This session introduces two basic elements of Heartfulness.
                  Relaxation and Meditation
                </h4>
              </div>
            </div>
            <div className="col-md-2">
              <div className="custom-left-border">
                <img className="img-fluid" src={mcrelax} alt="youtube" />
              </div>
            </div>
            <div className="col-md-5">
              <h2 className="time-title color-black schedule-h1">Relax. Meditate</h2>
              <h2 className="time-title color-black schedule-h2">Expand consciousness</h2>
              <h4 className="time-title-intro">
              In this first masterclass, learn Heartfulness Relaxation for physical-mental coherence and calm, followed by Heartfulness Meditation on the source of light in your heart to take you deeper within.
              </h4>
            </div>
          </div>
          <div className="row mg-bottom30">
            <div className="col-md-12">
              <div id="download-now">
                <h2 className="download-section text-center">
                  View masterclass in
                </h2>
                <p className="padd-b30"></p>
                <select
                  class="masterclass-lang-select"
                  name="masterclass video"
                  onChange={(e) => {
                    window.open(e.target.value, "_blank");
                  }}
                >
                  <option selected="selected" value="">
                    Select your language
                  </option>
                  <option value="https://youtu.be/-bGeWAKKckc">English</option>
                  <option value="https://www.youtube.com/watch?v=4t1QgK9jC-4&amp;feature=youtu.be">
                    Hindi
                  </option>
                  <option value="https://youtu.be/8d5L4W2D8Jo">Tamil</option>
                  <option value="https://youtu.be/UtGDdTBGYcg">Telugu</option>
                  <option value="https://youtu.be/QWccsvxbMh8">Kannada</option>
                  <option value="https://youtu.be/svYstAOSqR4">Bengali</option>
                  <option value="https://youtu.be/KUEdunz5jN8">Oriya</option>
                  <option value="https://youtu.be/8-wzjM1XdLY">Gujarati</option>
                  <option value="https://youtu.be/iGWikLMUyOQ">Nepali</option>
                  <option value="https://www.youtube.com/watch?v=lfP98XHX9iE">
                    Romanian
                  </option>
                  <option value="https://youtu.be/ukLt6VXjtE0">Russian</option>
                  <option value="https://youtu.be/saMj8unn-6w">French</option>
                  <option value="https://youtu.be/IQ3iYiMLC4M">German</option>
                  <option value="https://youtu.be/6d7Xbb9K_Z4">Marathi</option>
                  <option value="https://youtu.be/rYF0tbdNKUo">Persian</option>
                  <option value="https://youtu.be/AfFWWBYEGoo">Danish</option>
                  <option value="https://www.youtube.com/watch?v=gd4VrCnOD90">
                    Finnish
                  </option>
                  <option value="https://youtu.be/mYSCwkQouf4">Spanish</option>
                  <option value="https://youtu.be/4wpwPdNoYZk">Italian</option>
                  <option value="https://youtu.be/zL9e5uBwKF8">
                    Malayalam
                  </option>
                  <option value="//cdn-prod.heartfulness.org/videos/MC2018/RS720/MC%20Chinese%20Day1%20Jan%202018_720P.mp4">
                    Chinese
                  </option>
                  <option value="https://youtu.be/6TicgFmdzCo">
                    Portuguese (Brazil)
                  </option>
                  <option value="https://youtu.be/4y-Y2xA3fFE">Assamese</option>
                  <option value="https://youtu.be/wJzmNJFkd-A">
                    Sign language
                  </option>
                  <option value="https://youtu.be/qoWxVbBRp9k">
                    Portuguese (Portugal)
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="time-section2" id="select-your-language-day2">
          <div className="row">
            <div className="col-md-1">
              <h2 className="day-text text-center">Day</h2>
              <h2 className="month-text text-center pad-top20-safari">2</h2>
            </div>
            <div className="col-md-4">
              <div className="custom-left-border">
                <h4 className="time-title-intro">
                  The Cleaning practice. Rejuvenate and detox technique to let
                  go of stress and complexities.
                </h4>
              </div>
            </div>
            <div className="col-md-2">
              <div className="custom-left-border">
                <img className="img-fluid" src={rejuvente} alt="youtube" />
              </div>
            </div>
            <div className="col-md-5">
              <h2 className="time-title color-black schedule-h1 ">Rejuvenate.</h2>
              <h2 className="time-title color-black pad-top20-safari schedule-h2">Simplify</h2>
              <h4 className="time-title-intro">
              In this second masterclass, Daaji will guide you through a simple rejuvenative method to detox your mind. It is useful at the end of the day to let go of stress and complex emotions and will leave you feeling light and refreshed.
              </h4>
            </div>
          </div>
          <div className="row mg-bottom30">
            <div className="col-md-12">
              <div id="download-now">
                <h2 className="download-section text-center">
                  View masterclass in
                </h2>
                <p className="padd-b30"></p>
                <select
                  class="masterclass-lang-select"
                  name="masterclass video"
                  // onchange="{window.open(this.options[this.selectedIndex].value,'_blank')}"
                  onChange={(e) => {
                    window.open(e.target.value, "_blank");
                  }}
                >
                  <option selected="selected" value="">
                    Select your language
                  </option>
                  <option value="https://youtu.be/-bGeWAKKckc">English</option>
                  <option value="https://www.youtube.com/watch?v=4t1QgK9jC-4&amp;feature=youtu.be">
                    Hindi
                  </option>
                  <option value="https://youtu.be/8d5L4W2D8Jo">Tamil</option>
                  <option value="https://youtu.be/UtGDdTBGYcg">Telugu</option>
                  <option value="https://youtu.be/QWccsvxbMh8">Kannada</option>
                  <option value="https://youtu.be/svYstAOSqR4">Bengali</option>
                  <option value="https://youtu.be/KUEdunz5jN8">Oriya</option>
                  <option value="https://youtu.be/8-wzjM1XdLY">Gujarati</option>
                  <option value="https://youtu.be/iGWikLMUyOQ">Nepali</option>
                  <option value="https://www.youtube.com/watch?v=lfP98XHX9iE">
                    Romanian
                  </option>
                  <option value="https://youtu.be/ukLt6VXjtE0">Russian</option>
                  <option value="https://youtu.be/saMj8unn-6w">French</option>
                  <option value="https://youtu.be/IQ3iYiMLC4M">German</option>
                  <option value="https://youtu.be/6d7Xbb9K_Z4">Marathi</option>
                  <option value="https://youtu.be/rYF0tbdNKUo">Persian</option>
                  <option value="https://youtu.be/AfFWWBYEGoo">Danish</option>
                  <option value="https://www.youtube.com/watch?v=gd4VrCnOD90">
                    Finnish
                  </option>
                  <option value="https://youtu.be/mYSCwkQouf4">Spanish</option>
                  <option value="https://youtu.be/4wpwPdNoYZk">Italian</option>
                  <option value="https://youtu.be/zL9e5uBwKF8">
                    Malayalam
                  </option>
                  <option value="//cdn-prod.heartfulness.org/videos/MC2018/RS720/MC%20Chinese%20Day1%20Jan%202018_720P.mp4">
                    Chinese
                  </option>
                  <option value="https://youtu.be/6TicgFmdzCo">
                    Portuguese (Brazil)
                  </option>
                  <option value="https://youtu.be/4y-Y2xA3fFE">Assamese</option>
                  <option value="https://youtu.be/wJzmNJFkd-A">
                    Sign language
                  </option>
                  <option value="https://youtu.be/qoWxVbBRp9k">
                    Portuguese (Portugal)
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div id="select-your-language-day3" class="time-section3">
            <div class="row">
              <div class="col-md-1">
                <h2 class="day-text text-center">Day</h2>
                <h2 class="month-text text-center">3</h2>
              </div>
              <div class="col-md-4">
                <div class="custom-left-border">
                  <h4 class="time-title-intro">
                    The Connect masterclass. To establish the heart connection
                    and to listen to the guidance of the Heart.
                  </h4>
                </div>
              </div>
              <div class="col-md-2">
                <div class="custom-left-border">
                  <img className="img-fluid" src={mcconnect} alt="youtube" />
                </div>
              </div>
              <div class="col-md-5">
                <h2 className="time-title color-black schedule-h1">Connect.</h2>
                <h2 className="time-title color-black schedule-h2">With your inner self</h2>
                <h4 className="time-title-intro">
                In the third class, learn to connect with your inner self by listening to the heart’s voice. Observe your deepest feelings, make wise choices to weave your own destiny.
                </h4>
              </div>
            </div>
            <div className="row mg-bottom30">
              <div className="col-md-12">
                <div id="download-now">
                  <h2 className="download-section text-center">
                    View masterclass in
                  </h2>
                  <p className="padd-b30"></p>
                  <select
                    class="masterclass-lang-select"
                    name="masterclass video"
                    onChange={(e) => {
                      window.open(e.target.value, "_blank");
                    }}
                  >
                    <option selected="selected" value="">
                      Select your language
                    </option>
                    <option value="https://youtu.be/-bGeWAKKckc">
                      English
                    </option>
                    <option value="https://www.youtube.com/watch?v=4t1QgK9jC-4&amp;feature=youtu.be">
                      Hindi
                    </option>
                    <option value="https://youtu.be/8d5L4W2D8Jo">Tamil</option>
                    <option value="https://youtu.be/UtGDdTBGYcg">Telugu</option>
                    <option value="https://youtu.be/QWccsvxbMh8">
                      Kannada
                    </option>
                    <option value="https://youtu.be/svYstAOSqR4">
                      Bengali
                    </option>
                    <option value="https://youtu.be/KUEdunz5jN8">Oriya</option>
                    <option value="https://youtu.be/8-wzjM1XdLY">
                      Gujarati
                    </option>
                    <option value="https://youtu.be/iGWikLMUyOQ">Nepali</option>
                    <option value="https://www.youtube.com/watch?v=lfP98XHX9iE">
                      Romanian
                    </option>
                    <option value="https://youtu.be/ukLt6VXjtE0">
                      Russian
                    </option>
                    <option value="https://youtu.be/saMj8unn-6w">French</option>
                    <option value="https://youtu.be/IQ3iYiMLC4M">German</option>
                    <option value="https://youtu.be/6d7Xbb9K_Z4">
                      Marathi
                    </option>
                    <option value="https://youtu.be/rYF0tbdNKUo">
                      Persian
                    </option>
                    <option value="https://youtu.be/AfFWWBYEGoo">Danish</option>
                    <option value="https://www.youtube.com/watch?v=gd4VrCnOD90">
                      Finnish
                    </option>
                    <option value="https://youtu.be/mYSCwkQouf4">
                      Spanish
                    </option>
                    <option value="https://youtu.be/4wpwPdNoYZk">
                      Italian
                    </option>
                    <option value="https://youtu.be/zL9e5uBwKF8">
                      Malayalam
                    </option>
                    <option value="//cdn-prod.heartfulness.org/videos/MC2018/RS720/MC%20Chinese%20Day1%20Jan%202018_720P.mp4">
                      Chinese
                    </option>
                    <option value="https://youtu.be/6TicgFmdzCo">
                      Portuguese (Brazil)
                    </option>
                    <option value="https://youtu.be/4y-Y2xA3fFE">
                      Assamese
                    </option>
                    <option value="https://youtu.be/wJzmNJFkd-A">
                      Sign language
                    </option>
                    <option value="https://youtu.be/qoWxVbBRp9k">
                      Portuguese (Portugal)
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </section>
    
  );
}
